import { SkeletonBody } from "sholdi-primitives/atoms/Skeleton";

const CardListSkeleton = () => (
  <div className="w-full">
    <SkeletonBody className="w-[160px] h-6 block mb-10" />

    <div className="grid grid-rows-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 overflow-hidden">
      {Array(6)
        .fill(0)
        .map((_, i) => (
          <SkeletonBody key={i} className="h-[135px]" />
        ))}
    </div>
  </div>
);

export default CardListSkeleton;
