import { SkeletonBody } from "sholdi-primitives/atoms/Skeleton";

const GallerySkeleton = () => (
  <div className="flex items-center w-full gap-1 lg:gap-4">
    <SkeletonBody className="size-11.5 flex-none" />
    <div className="flex items-center w-full gap-1 lg:gap-4">
      {Array(6)
        .fill(0)
        .map((_, i) => (
          <SkeletonBody key={i} className="min-w-56 h-56" />
        ))}
    </div>
    <SkeletonBody className="size-11.5 flex-none" />
  </div>
);

export default GallerySkeleton;
