import { twMerge } from "tailwind-merge";

import { SkeletonBody } from "sholdi-primitives/atoms/Skeleton";

const GridSkeleton = () => (
  <div className="grid w-full h-[700px] grid-cols-2 md:grid-cols-4 gap-6">
    {Array(6)
      .fill(0)
      .map((_, i) => (
        <SkeletonBody
          key={i}
          className={twMerge(
            "row-span-1 rounded",
            i === 2 || i === 4 ? "col-span-2" : "",
          )}
        />
      ))}
  </div>
);

export default GridSkeleton;
